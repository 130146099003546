import React, { useCallback, useEffect, useRef, useState } from "react";


import InfoIcon from "@mui/icons-material/Info";
import {
  colors
} from "@mui/material";
import Box from "@mui/material/Box";
import { blue } from "@mui/material/colors";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import { BEREdited } from "common/components/BEREdited";
import {
  DwellingAndScenarioPerformanceBanner
} from "common/components/DwellingAndScenarioPerformanceBanner";
import iconTitleHomeBlank from "common/icons/icon-title-home-blank.png";
import {
  useHomeEnergyAssessment
} from "features/homeEnergyAssessment/hooks/useHomeEnergyAssessment";

import type { Dwelling } from "features/dwellings/types";


interface HomeEnergyAssessmentDialogContent9Props {
  dwelling: Dwelling;
  contentBackgroundColour: string;
  pageBackgroundColour: string;
  scenarioDetail: string;
  scenarioRating: string;
  inputError: boolean;
  setInputError: React.Dispatch<React.SetStateAction<boolean>>;
  handleHeaFieldBlur: (
    value: string | number | File,
    field: string
  ) => void;
}


export function DialogContentSteps(
  {
    dwelling,
    contentBackgroundColour,
    pageBackgroundColour,
    scenarioDetail,
    scenarioRating,
    inputError,
    setInputError,
    handleHeaFieldBlur
  }: HomeEnergyAssessmentDialogContent9Props
) {

  const {
    homeEnergyAssessment
  } = useHomeEnergyAssessment();

  const [
    sectionHeadingNextSteps,
    setSectionHeadingNextSteps
  ] = useState<string>("");

  const [
    sectionHeadingNextStepsError,
    setSectionHeadingNextStepsError
  ] = useState<boolean>(false);

  const [
    sectionHeadingNextStepsLength,
    setSectionHeadingNextStepsLength
  ] = useState<number>(0);

  const sectionHeadingNextStepsLengthLimit = 100;

  const sectionHeadingNextStepsInputRef = useRef<HTMLInputElement>();

  const [
    nextStepsParagraph,
    setNextStepsParagraph
  ] = useState<string>("");

  const [
    nextStepsParagraphError,
    setNextStepsParagraphError
  ] = useState<boolean>(false);

  const [
    nextStepsParagraphLength,
    setNextStepsParagraphLength
  ] = useState<number>(0);

  const nextStepsParagraphLengthLimit = 1000;

  const nextStepsParagraphInputRef = useRef<HTMLInputElement>();

  useEffect(
    () => {

      if (
        sectionHeadingNextStepsError ||
        nextStepsParagraphError
      ) {
        setInputError(true);
      }

      if (inputError) {
        if (
          !sectionHeadingNextStepsError &&
          !nextStepsParagraphError
        ) {
          setInputError(false);
        }
      }

    },
    [
      sectionHeadingNextStepsError,
      nextStepsParagraphError,
      inputError,
      setInputError
    ]
  );

  useEffect(
    () => {

      if (homeEnergyAssessment !== undefined) {
        setSectionHeadingNextSteps(
          homeEnergyAssessment.section_heading_next_steps
        )

        setSectionHeadingNextStepsLength(
          homeEnergyAssessment.section_heading_next_steps !== null ?
            homeEnergyAssessment.section_heading_next_steps.length : 0
        )

        setNextStepsParagraph(
          homeEnergyAssessment.next_steps_paragraph
        );

        setNextStepsParagraphLength(
          homeEnergyAssessment.next_steps_paragraph !== null ?
            homeEnergyAssessment.next_steps_paragraph.length : 0
        );
      }
    },
    [
      homeEnergyAssessment
    ]
  );

  const handleFieldUpdated = useCallback(
    (
      value: string | number | File,
      field: string
    ) => {
      handleHeaFieldBlur(value, field);
    },
    [
      handleHeaFieldBlur
    ]
  );

  const theme = useTheme();

  return (
    <DialogContent
      sx={{
        width: "100%",
        flex: 1,
        overflowX: "hidden",
        overflowY: "hidden",
        padding: "0px 8px 0px 8px",
        gap: "2rem"
      }}
    >

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderRadius: "20px",
            backgroundColor: contentBackgroundColour,
            padding: "16px"
          }}
        >
          <img
            src={iconTitleHomeBlank}
            alt="Icon"
            style={{
              maxHeight: "48px"
            }}
          />

          <TextField
            error={sectionHeadingNextStepsError}
            variant="standard"
            fullWidth
            inputProps={{
              style: {
                fontSize: "1.4rem",
                backgroundColor: theme.palette.common.white
              }
            }}
            InputLabelProps={{
              style: {
                fontSize: "1.4rem"
              }
            }}
            FormHelperTextProps={{
              sx: {
                textAlign: "right",
                width: "100%"
              }
            }}
            value={sectionHeadingNextSteps}
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: "16px",
              color: colors.grey["800"]
            }}
            onBlur={(event) => {
              if (!inputError) {
                handleFieldUpdated(
                  event.target.value,
                  "section_heading_next_steps"
                );
              }
            }}
            onChange={(event) => {
              const {
                value
              } = event.target;
              setSectionHeadingNextStepsLength(value.length);
              if (value.length > sectionHeadingNextStepsLengthLimit) {
                setSectionHeadingNextStepsError(true);
              } else if (sectionHeadingNextStepsError) {
                setSectionHeadingNextStepsError(false);
              }
              setSectionHeadingNextSteps(value);
            }}
            helperText={
              sectionHeadingNextStepsLengthLimit - sectionHeadingNextStepsLength <= (sectionHeadingNextStepsLengthLimit / 2) ?
                `${sectionHeadingNextStepsLength}/${sectionHeadingNextStepsLengthLimit}` : ""
            }
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                if (!inputError) {
                  if (
                    nextStepsParagraphInputRef !== undefined &&
                    nextStepsParagraphInputRef.current !== undefined
                  ) {
                    nextStepsParagraphInputRef.current.focus();
                  }
                }
              }
            }}
            inputRef={sectionHeadingNextStepsInputRef}
          />

        </Box>

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <DwellingAndScenarioPerformanceBanner
          dwellingDetail={String(dwelling.mprn)}
          dwellingRating={dwelling.performance.rating}
          scenarioDetail={scenarioDetail}
          scenarioRating={scenarioRating}
        />

      </Box>

      {
        dwelling.performance.ber_edited ? <BEREdited/> : null
      }

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px",
          display: "flex",
          flexDirection: "row"
        }}
      >
        <TextField
          error={nextStepsParagraphError}
          sx={{
            backgroundColor: pageBackgroundColour,
            width: "100%",
            flex: 1
          }}
          multiline
          size="small"
          variant="outlined"
          InputProps={{
            sx: {
              backgroundColor: theme.palette.common.white
            }
          }}
          FormHelperTextProps={{
            sx: {
              textAlign: "right"
            }
          }}
          value={nextStepsParagraph}
          onBlur={(event) => {
            if (!inputError) {
              handleFieldUpdated(
                event.target.value,
                "next_steps_paragraph"
              );
            }
          }}
          onChange={(event) => {
            const {
              value
            } = event.target;
            setNextStepsParagraphLength(value.length);
            if (value.length > nextStepsParagraphLengthLimit) {
              setNextStepsParagraphError(true);
            } else if (nextStepsParagraphError) {
              setNextStepsParagraphError(false);
            }
            setNextStepsParagraph(value)
          }}
          helperText={
            `${nextStepsParagraphLength}/${nextStepsParagraphLengthLimit}`
          }
          inputRef={nextStepsParagraphInputRef}
        />


        <Box
          sx={{
            paddingLeft: "8px"
          }}
        >
          <Tooltip
            title="New lines (Enter) here are treated as individual numbered steps in the PDF"
          >
            <InfoIcon
              sx={{
                color: blue[500]
              }}
            />
          </Tooltip>
        </Box>

      </Box>

    </DialogContent>
  );
}