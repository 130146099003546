import React from "react";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { BERChevron } from "common/components/BERChevron";


import type { Column } from "@material-table/core";
import type { CustomScenario } from "features/customScenarios/types";
import type { DefaultScenario } from "features/defaultScenarios/types";
import type { Dwelling } from "features/dwellings/types";
import type {
  HomeEnergyAssessmentCostEstimatesBottomTableData,
  HomeEnergyAssessmentCostEstimatesTableData,
  HomeEnergyAssessmentDetailedTableData,
  HomeEnergyAssessmentRenovationTableData
} from "features/homeEnergyAssessment/types";
import type {
  ProjectCostsLowerTableData,
  ProjectCostsTableData
} from "features/projectCosts/types";
import type { ProjectScenario } from "features/projectScenarios/types";



export const DWELLING_TABLE_COLUMNS: Array<Column<Dwelling>> = [
  {
    title: "MPRN",
    field: "mprn",
    type: "numeric",
    width: 90,
    cellStyle: {
      textAlign: "right",
      padding: "0px 16px 0px 16px"
    }
  },
  {
    title: "Street",
    field: "street",
    type: "string",
    width: 100,
    cellStyle: {
      padding: "0 16px 0 16px"
    }
  },
  {
    title: "Area",
    field: "area",
    type: "string",
    width: 100,
    cellStyle: {
      padding: "0 16px 0 16px"
    }
  },
  {
    title: "City",
    field: "city",
    type: "string",
    width: 60,
    cellStyle: {
      padding: "0 16px 0 16px"
    }
  },
  {
    title: "County",
    field: "county",
    type: "string",
    width: 60,
    cellStyle: {
      padding: "0 16px 0 16px"
    }
  },
  {
    title: "Postcode",
    field: "postcode",
    type: "string",
    width: 70,
    cellStyle: {
      padding: "0 16px 0 16px"
    }
  }
];


export const PROJECT_SCENARIO_TABLE_COLUMNS: Array<Column<ProjectScenario>> = [
  {
    title: "Name",
    field: "title",
    type: "string",
    cellStyle: {
      textAlign: "left",
      paddingLeft: "8px",
      padding: "0 16px 0 16px"
    }
  },
  {
    title: "Description",
    field: "description",
    type: "string",
    cellStyle: {
      textAlign: "left",
      padding: "0 16px 0 16px"
    }
  },
  {
    title: "Measures",
    customSort: (scenarioA, scenarioB) =>
      (scenarioA.measures.length + scenarioA.custom_measures.length) -
      (scenarioB.measures.length + scenarioB.custom_measures.length),
    render: (rowData) => rowData.measures.length + rowData.custom_measures.length,
    type: "numeric",
    width: 20,
    cellStyle: {
      textAlign: "right",
      padding: "0 16px 0 16px"
    },
    headerStyle: {
      width: 20
    }
  }
];

export const ADD_SCENARIO_TO_PROJECT_TABLE_COLUMNS: Array<Column<CustomScenario | DefaultScenario>> = [
  {
    title: "Name",
    field: "title",
    type: "string",
    cellStyle: {
      textAlign: "left",
      padding: "0 16px 0 16px"
    }
  },
  {
    title: "Description",
    field: "description",
    type: "string",
    cellStyle: {
      textAlign: "left",
      padding: "0 16px 0 16px"
    }
  },
  {
    title: "Measures",
    customSort: (scenarioA, scenarioB) => {

      if ("custom_measures" in scenarioA && "custom_measures" in scenarioB) {
        return (scenarioA.measures.length + scenarioA.custom_measures.length) -
          (scenarioB.measures.length + scenarioB.custom_measures.length);
      }

      return scenarioA.measures.length - scenarioB.measures.length;
    },
    render: (rowData: CustomScenario | DefaultScenario) => {

      if ("custom_measures" in rowData) {
        return rowData.measures.length + rowData.custom_measures.length;
      }

      return rowData.measures.length;
    },
    type: "numeric",
    cellStyle: {
      textAlign: "right",
      padding: "0 16px 0 16px"
    }
  }
];

export const PROJECT_COSTS_TABLE_COLUMNS: Array<Column<ProjectCostsTableData>> = [
  {
    title: "Step",
    field: "step",
    type: "numeric",
    cellStyle: {
      textAlign: "right"
    },
    customSort: (a, b) => a.step - b.step,
    defaultSort: "asc",
    editable: 'never',
    sorting: true,
    width: '8%'
  },
  {
    title: "Measure",
    field: "measure_title",
    type: "string",
    cellStyle: {
      textAlign: "left"
    },
    editable: 'never',
    sorting: false,
    width: '37%'
  },
  {
    title: "Cost (incl VAT)",
    field: "cost",
    type: "currency",
    cellStyle: {
      textAlign: "right"
    },
    currencySetting: {
      currencyCode: 'EUR',
      locale: 'ga-IE',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    },
    editable: 'always',
    editComponent: props => (
      <TextField
        type="number"
        value={Number(props.value).toFixed(0)}
        onChange={event => props.onChange(
          event.target.value
        )}
        inputProps={{
          inputMode: 'numeric',
          pattern: '/^-?\d+(?:\.\d+)?$/g'
        }}
        sx={{
          backgroundColor: "#FFFFFF",
          "& .MuiOutlinedInput-input": {
            padding: '8px'
          }
        }}
      />
    ),
    sorting: false,
    width: "25%"
  },
  {
    title: "Grant",
    field: "grant",
    type: "currency",
    cellStyle: {
      textAlign: "right"
    },
    currencySetting: {
      currencyCode: 'EUR',
      locale: 'ga-IE',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    },
    editable: 'always',
    editComponent: props => (
      <TextField
        type="number"
        value={Number(props.value).toFixed(0)}
        onChange={event => props.onChange(
          event.target.value
        )}
        inputProps={{
          inputMode: 'numeric',
          pattern: '/^-?\d+(?:\.\d+)?$/g'
        }}
        sx={{
          backgroundColor: "#FFFFFF",
          "& .MuiOutlinedInput-input": {
            padding: '8px'
          }
        }}
      />
    ),
    sorting: false,
    width: "25%"
  },
];

export const PROJECT_COSTS_LOWER_TABLE_COLUMNS: Array<Column<ProjectCostsLowerTableData>> = [
  {
    title: "ID",
    field: "id",
    type: "numeric",
    customSort: (a, b) => a.id - b.id,
    defaultSort: "asc",
    editable: 'never',
    render: () => <div/>,
    sorting: true,
    width: '8%'
  },
  {
    title: "Title",
    field: "title",
    type: "string",
    cellStyle: {
      textAlign: "left",
      fontWeight: "bold"
    },
    editable: 'never',
    sorting: false,
    width: '37%'
  },
  {
    title: "Cost",
    field: "cost",
    type: "currency",
    cellStyle: {
      textAlign: "right",
      fontWeight: "bold"
    },
    currencySetting: {
      currencyCode: 'EUR',
      locale: 'ga-IE',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    },
    editComponent: props => (
      <TextField
        type="number"
        value={Number(props.value).toFixed(0)}
        onChange={event => props.onChange(
          event.target.value
        )}
        inputProps={{
          inputMode: 'numeric',
          pattern: '/^-?\d+(?:\.\d+)?$/g'
        }}
        sx={{
          backgroundColor: "#FFFFFF",
          "& .MuiOutlinedInput-input": {
            padding: '8px'
          }
        }}
      />
    ),
    render: (rowData) => {
      if (rowData.cost === 0) {
        return <div />;
      }
      return `€${Number(rowData.cost).toFixed(0)}`
    },
    sorting: false,
    width: "25%"
  },
  {
    title: "Grant",
    field: "grant",
    type: "currency",
    cellStyle: {
      textAlign: "right",
      fontWeight: "bold"
    },
    currencySetting: {
      currencyCode: 'EUR',
      locale: 'ga-IE',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    },
    editComponent: props => (
      <TextField
        type="number"
        value={Number(props.value).toFixed(0)}
        onChange={event => props.onChange(
          event.target.value
        )}
        inputProps={{
          inputMode: 'numeric',
          pattern: '/^-?\d+(?:\.\d+)?$/g'
        }}
        sx={{
          backgroundColor: "#FFFFFF",
          "& .MuiOutlinedInput-input": {
            padding: '8px'
          }
        }}
      />
    ),
    render: (rowData) => {
      if (rowData.grant === 0) {
        return <div />;
      }
      return `€${Number(rowData.grant).toFixed(0)}`
    },
    sorting: false,
    width: "25%"
  },
]

export const HEA_RENOVATION_TABLE_COLUMNS: Array<Column<HomeEnergyAssessmentRenovationTableData>> = [
  {
    title: "Step",
    field: "step",
    type: "numeric",
    customSort: (a, b) => a.step - b.step,
    defaultSort: "asc",
    editable: 'never',
    sorting: false,
    width: '8%'
  },
  {
    title: "Measure",
    field: "energy_upgrade_measure",
    type: "string",
    editable: 'never',
    sorting: false,
    width: '37%'
  },
  {
    title: "BER",
    field: "ber",
    type: "string",
    editable: 'never',
    render: (rowData) => <BERChevron
      rating={rowData.ber}
    />,
    sorting: false,
    width: '25%'
  },
  {
    title: "HLI",
    field: "hli",
    type: "string",
    editable: 'never',
    render: (rowData) => {

      let colour = '#f44336'

      if (rowData.hli <= 2.3) {
        colour = '#4caf50'
      }

      return <Typography
        sx={{
          color: colour,
          fontWeight: "bold"
        }}
      >
        {rowData.hli.toFixed(2)}
      </Typography>
    },
    sorting: false,
    width: '25%'
  },
  {
    title: "Uplift",
    field: "uplift",
    type: "string",
    editable: 'never',
    render: (rowData) => {
      const number = Math.round(rowData.uplift * 100) / 100

      let colour = '#f44336'

      if (number > 100) {
        colour = '#4caf50'
      }
      return <Typography
        sx={{
          color: colour,
          fontWeight: "bold"
        }}
      >
        {number.toFixed(0)}
      </Typography>
    },
    sorting: false,
    width: '25%'
  },
]


export const HEA_TECHNICAL_TABLE_COLUMNS: Array<Column<HomeEnergyAssessmentDetailedTableData>> = [
  {
    title: "Step",
    field: "step",
    type: "numeric",
    customSort: (a, b) => a.step - b.step,
    defaultSort: "asc",
    editable: 'never',
    sorting: false,
    width: '8%'
  },
  {
    title: "Measure",
    field: "energy_upgrade_measure",
    type: "string",
    editable: 'never',
    sorting: false,
    width: '37%'
  },
  {
    title: "Minimum Performance Specs",
    field: "min_perf_spec",
    type: "string",
    editable: 'never',
    sorting: false,
    width: '25%'
  },
  {
    title: "Quantity",
    field: "quantity",
    type: "numeric",
    editable: 'never',
    sorting: false,
    width: '25%'
  }
]


export const HEA_COST_ESTIMATES_TABLE_COLUMNS: Array<Column<HomeEnergyAssessmentCostEstimatesTableData>> = [
  {
    title: "Step",
    field: "step",
    type: "numeric",
    customSort: (a, b) => a.step - b.step,
    defaultSort: "asc",
    editable: 'never',
    sorting: false,
    width: '8%'
  },
  {
    title: "Measure",
    field: "energy_upgrade_measure",
    type: "string",
    editable: 'never',
    sorting: false,
    width: '37%'
  },
  {
    title: "Cost (incl. VAT)",
    field: "cost",
    type: "numeric",
    editable: 'never',
    render: (rowData) =>
      `€${Number(rowData.cost).toFixed(0)}`,
    sorting: false,
    width: '25%'
  },
  {
    title: "Grants",
    field: "grant",
    type: "numeric",
    editable: 'never',
    render: (rowData) =>
      `€${Number(rowData.grant).toFixed(0)}`,
    sorting: false,
    width: '25%'
  }
]

export const HEA_COST_ESTIMATES_BOTTOM_TABLE_COLUMNS: Array<Column<HomeEnergyAssessmentCostEstimatesBottomTableData>> = [
  {
    field: "id",
    type: "numeric",
    customSort: (a, b) => a.id - b.id,
    defaultSort: "asc",
    editable: 'never',
    render: () => (<div />),
    sorting: false,
    width: '8%'
  },
  {
    field: "title",
    type: "string",
    editable: 'never',
    sorting: false,
    width: '37%'
  },
  {
    field: "cost",
    type: "numeric",
    editable: 'never',
    render: (rowData) => {
      if (rowData.cost === 0) {
        return <div />;
      }
      return `€${Number(rowData.cost).toFixed(0)}`
    },
    sorting: false,
    width: '25%'
  },
  {
    field: "grant",
    type: "numeric",
    editable: 'never',
    render: (rowData) => {
      if (rowData.grant === 0) {
        return <div />;
      }
      return `€${Number(rowData.grant).toFixed(0)}`
    },
    sorting: false,
    width: '25%'
  }
]
