import { retrokitApi } from "common/api/retrokitApi";

import type {
  CreateOrGetHEAApiRequest,
  UpdateHEASectionDetailsApiRequest,
  UpdateHEASectionObjectivesApiRequest,
  UpdateHEASectionCurrentApiRequest,
  UpdateHEASectionSequenceApiRequest,
  UpdateHEASectionDesignApiRequest,
  UpdateHEASectionNotesApiRequest,
  UpdateHEASectionTermsApiRequest,
  UpdateHEASectionCostsApiRequest,
  UpdateHEASectionAnnexesApiRequest,
  UpdateHEASectionImpactApiRequest,
  UpdateHEASectionPlanApiRequest,
  UpdateHEASectionStepsApiRequest
} from "./types";
import type { HomeEnergyAssessment } from "../types";

import {
  heaApiTransformer,
  heaApiUpdateTransformer
} from "./transformers";

export const heaApi = retrokitApi.injectEndpoints({
  endpoints: (builder) => ({
    createOrGetHEA: builder.query<
      HomeEnergyAssessment,
      CreateOrGetHEAApiRequest
    >({
      query: (arg) => ({
        url: `integrations/reports/hea`,
        method: "POST",
        body: arg
      }),
      providesTags: [
        "HomeEnergyAssessment"
      ],
      transformResponse: heaApiTransformer
    }),

    updateHEA: builder.mutation<
      HomeEnergyAssessment,
      {
        heaUpdate: UpdateHEASectionDetailsApiRequest |
          UpdateHEASectionObjectivesApiRequest |
          UpdateHEASectionImpactApiRequest |
          UpdateHEASectionCurrentApiRequest |
          UpdateHEASectionPlanApiRequest |
          UpdateHEASectionSequenceApiRequest |
          UpdateHEASectionDesignApiRequest |
          UpdateHEASectionNotesApiRequest |
          UpdateHEASectionStepsApiRequest |
          UpdateHEASectionTermsApiRequest |
          UpdateHEASectionCostsApiRequest |
          UpdateHEASectionAnnexesApiRequest,
        existingHEACacheKey: CreateOrGetHEAApiRequest
      }
    >({
      query: (arg) => ({
        url: `integrations/reports/hea`,
        method: "PUT",
        body: heaApiUpdateTransformer(
          arg.heaUpdate
        )
      }),
      transformResponse: heaApiTransformer
    })
  })
});

export const {
  useLazyCreateOrGetHEAQuery,
  useUpdateHEAMutation
} = heaApi;