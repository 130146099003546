import type {
  HomeEnergyAssessmentApiResponse,
  UpdateHEASectionDetailsApiRequest,
  UpdateHEASectionObjectivesApiRequest,
  UpdateHEASectionImpactApiRequest,
  UpdateHEASectionCurrentApiRequest,
  UpdateHEASectionPlanApiRequest,
  UpdateHEASectionSequenceApiRequest,
  UpdateHEASectionDesignApiRequest,
  UpdateHEASectionNotesApiRequest,
  UpdateHEASectionStepsApiRequest,
  UpdateHEASectionTermsApiRequest,
  UpdateHEASectionCostsApiRequest,
  UpdateHEASectionAnnexesApiRequest
} from "./types";
import type { HomeEnergyAssessment } from "../types";

export function heaApiTransformer(
  response: HomeEnergyAssessmentApiResponse
): HomeEnergyAssessment {
  return {
    ...response,
    logoFile: undefined,
    dwellingImageFile: undefined,
    annexAdditionalFiles: undefined
  };
}

export function heaApiUpdateTransformer(
  variable: UpdateHEASectionDetailsApiRequest |
    UpdateHEASectionObjectivesApiRequest |
    UpdateHEASectionImpactApiRequest |
    UpdateHEASectionCurrentApiRequest |
    UpdateHEASectionPlanApiRequest |
    UpdateHEASectionSequenceApiRequest |
    UpdateHEASectionDesignApiRequest |
    UpdateHEASectionNotesApiRequest |
    UpdateHEASectionStepsApiRequest |
    UpdateHEASectionTermsApiRequest |
    UpdateHEASectionCostsApiRequest |
    UpdateHEASectionAnnexesApiRequest
): FormData {
  const formData = new FormData();
  Object.entries(
    variable
  ).forEach(
    (entry) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const [key, value] = entry;
      if (key === 'additional_annex_files') {
        if (value instanceof Array) {
          for (let i = 0; i < value.length; i += 1) {
            formData.append(
              `${key}${i}`,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              value[i]
            );

          }
        }
      }
      else {
        formData.append(
          key,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          value
        );
      }
    }
  );
  return formData;
}